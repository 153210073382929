<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS SOCIOS DE NEGOCIO:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="vr my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-users fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaSociosNegocio.length}}</span>
                            <br>
                            <span class="text-muted">Socios de negocio antisoborno registrados</span>
                        </b-col>
                        <b-col cols="6" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-user-tie fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaFuncionarios.length}}</span>
                            <br>
                            <span class="text-muted">Funcionarios públicos registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de socios de negocio</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('017-37001-SON','c') == 1" cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarSocioNegocio = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo socio de negocio antisoborno</span>
                            </b-button>
                        </b-col>
                        <b-col v-if="checkPermissions('017-37001-SON','c') == 1" cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarFuncionarioPublico = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo funcionario público</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Socios de negocio registrados </span>
                </CCardHeader>
                <CCardBody>
                    <b-tabs content-class="mt-3">
                        <b-tab title="Socios de negocio antisoborno" active>
                            <b-row>
                                <b-col md="2">
                                    <b-form-group label="Registros por página" class="text-muted">
                                        <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col md="7">
                                </b-col>
                                <b-col md="3">
                                    <b-form-group label="Busqueda:" class="text-muted">
                                        <b-input-group size="xl">
                                            <b-input-group-prepend is-text>
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </b-input-group-prepend>
                                            <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <b-table bordered hover show-empty mediun responsive outlined :items="listaSociosNegocio" :fields="camposPoliticas" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                        <template v-slot:cell(opciones)="param">
                                            <b-button v-if="checkPermissions('017-37001-SON','u') == 1" size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarSocioNegocio(param)" variant="dark" class=" mr-1 mb-1">
                                                <span class="btn-inner--icon">
                                                    <i class="fas fa-search my-0 mx-0"></i>
                                                </span>
                                            </b-button>
                                            <b-button v-if="checkPermissions('017-37001-SON','d') == 1" size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarSocioNegocio(param)" variant="danger">
                                                <span class="btn-inner--icon">
                                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                </span>
                                            </b-button>
                                        </template>
                                        <template v-slot:cell(isActivo)="param">
                                            <span v-if="param.item.isActivo==2">Activo</span>
                                            <span v-if="param.item.isActivo==1">Suspendido</span>
                                        </template>
                                        <template v-slot:cell(idTipoSocioFuncionario)="param">
                                            <span v-if="param.item.idTipoSocioFuncionario==-1">Proveedor</span>
                                            <span v-if="param.item.idTipoSocioFuncionario==-2">Cliente</span>
                                            <span v-if="param.item.idTipoSocioFuncionario>0">{{listaTipoSociosNegocioAntisoborno.find(x => x.idTipoSocioNegocio==param.item.idTipoSocioFuncionario).descripcion}}</span>
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-tab>
                        <b-tab title="Funcionarios públicos">
                            <b-row>
                                <b-col md="2">
                                    <b-form-group label="Registros por página" class="text-muted">
                                        <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col md="7">
                                </b-col>
                                <b-col md="3">
                                    <b-form-group label="Busqueda:" class="text-muted">
                                        <b-input-group size="xl">
                                            <b-input-group-prepend is-text>
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </b-input-group-prepend>
                                            <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <b-table bordered hover show-empty mediun responsive outlined :items="listaFuncionarios" :fields="camposPoliticas" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                        <template v-slot:cell(opciones)="param">
                                            <b-button v-if="checkPermissions('017-37001-SON','u') == 1" size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarFuncionarioPublico(param)" variant="dark" class=" mr-1 mb-1">
                                                <span class="btn-inner--icon">
                                                    <i class="fas fa-search my-0 mx-0"></i>
                                                </span>
                                            </b-button>
                                            <b-button v-if="checkPermissions('017-37001-SON','d') == 1" size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarSocioNegocio(param)" variant="danger">
                                                <span class="btn-inner--icon">
                                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                </span>
                                            </b-button>
                                        </template>
                                        <template v-slot:cell(isActivo)="param">
                                            <span v-if="param.item.isActivo==2">Activo</span>
                                            <span v-if="param.item.isActivo==1">Suspendido</span>
                                        </template>
                                        <template v-slot:cell(idTipoSocioFuncionario)="param">
                                            <span v-if="param.item.idTipoSocioFuncionario>0">{{listaTipoFuncionariosPublicos.find(x => x.idTipoFuncionario==param.item.idTipoSocioFuncionario).descripcion}}</span>
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-tab>
                    </b-tabs>

                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarSocioNegocio" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo socio de negocio antisoborno </span>
            </h6>
            <CButtonClose @click="modalRegistrarSocioNegocio = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarSocioNegocio)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="el socio de negocio es">
                            <b-form-group label="El socio de negocio es:" class="mb-2">
                                <b-form-radio-group v-on:change="resetInput(1)" plain v-model="datosSocioNegocio.isSocioNegocio" :options="[{value:2,text:'Cliente'},{value:1,text:'Proveedor'},{value:3,text:'Nuevo socio de negocio'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosSocioNegocio.isSocioNegocio==2">
                        <validation-provider name="cliente" rules="required" v-slot="{errors}">
                            <b-form-group label="Cliente:" class="mb-2">
                                <v-select :reduce="listaClientes =>listaClientes.idClienteProyecto" label="razonSocial" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosSocioNegocio.idClienteProveedor , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosSocioNegocio.idClienteProveedor" :options="listaClientes" @search:blur="blurCliente1">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Lista de Clientes'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosSocioNegocio.isSocioNegocio==1">
                        <validation-provider name="proveedor" rules="required" v-slot="{errors}">
                            <b-form-group label="Proveedor:" class="mb-2">
                                <v-select :reduce="listaProveedores =>listaProveedores.idProveedor" label="razonSocial" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosSocioNegocio.idClienteProveedor , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosSocioNegocio.idClienteProveedor" :options="listaProveedores" @search:blur="blurProveedor1">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Proveedores'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosSocioNegocio.isSocioNegocio==3">
                        <validation-provider name="socio de negocio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Socio de negocio:" class="mb-2">
                                <b-form-select :state="getValidationState(validationContext)" v-model="datosSocioNegocio.idTipoSocioFuncionario" value-field="idTipoSocioNegocio" text-field="descripcion" :options="listaTipoSociosNegocioAntisoborno">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosSocioNegocio.isSocioNegocio==3">
                        <validation-provider name="n° de ruc" :rules="{numeric:true,max:11}" v-slot="validationContext">
                            <b-form-group label="N° de ruc:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el ruc" v-model="datosSocioNegocio.numeroRuc"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosSocioNegocio.isSocioNegocio==3">
                        <validation-provider name="razón social" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Razón Social:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la razón social" v-model="datosSocioNegocio.razonSocial"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosSocioNegocio.isSocioNegocio==3">
                        <validation-provider name="contacto" v-slot="validationContext">
                            <b-form-group label="Contacto:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el contacto" v-model="datosSocioNegocio.contacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosSocioNegocio.isSocioNegocio==3">
                        <validation-provider name="teléfono" :rules="{numeric:true}" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosSocioNegocio.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosSocioNegocio.isSocioNegocio==3">
                        <validation-provider name="email" :rules="{email:true}" v-slot="validationContext">
                            <b-form-group label="Email:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el email" v-model="datosSocioNegocio.email"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo de relación" v-slot="validationContext">
                            <b-form-group label="Tipo de relación:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el tipo de relación" v-model="datosSocioNegocio.tipoRelacion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="Estado del socio de negocio">
                            <b-form-group label="Estado del socio de negocio:" class="mb-2">
                                <b-form-radio-group plain v-model="datosSocioNegocio.isActivo" :options="[{value:2,text:'Activo'},{value:1,text:'Suspendido'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="detalles" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Detalles:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese los detalles" v-model="datosSocioNegocio.detalles"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarSocioNegocio = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarSocioNegocio" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar socio de negocio antisoborno</span></h6>
            <CButtonClose @click="modalActualizarSocioNegocio = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarSocioNegocio)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="el socio de negocio es">
                            <b-form-group label="El socio de negocio es:" class="mb-2">
                                <b-form-radio-group v-on:change="resetInput(2)" plain v-model="datosActualizarSocioNegocio.isSocioNegocio" :options="[{value:2,text:'Cliente'},{value:1,text:'Proveedor'},{value:3,text:'Nuevo socio de negocio'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosActualizarSocioNegocio.isSocioNegocio==2">
                        <validation-provider name="cliente" rules="required" v-slot="{errors}">
                            <b-form-group label="Cliente:" class="mb-2">
                                <v-select :reduce="listaClientes =>listaClientes.idClienteProyecto" label="razonSocial" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarSocioNegocio.idClienteProveedor , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosActualizarSocioNegocio.idClienteProveedor" :options="listaClientes" @search:blur="blurCliente1Actualizar">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Lista de Clientes'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosActualizarSocioNegocio.isSocioNegocio==1">
                        <validation-provider name="proveedor" rules="required" v-slot="{errors}">
                            <b-form-group label="Proveedor:" class="mb-2">
                                <v-select :reduce="listaProveedores =>listaProveedores.idProveedor" label="razonSocial" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarSocioNegocio.idClienteProveedor , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosActualizarSocioNegocio.idClienteProveedor" :options="listaProveedores" @search:blur="blurProveedor1Actualizar">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Proveedores'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosActualizarSocioNegocio.isSocioNegocio==3">
                        <validation-provider name="socio de negocio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Socio de negocio:" class="mb-2">
                                <b-form-select :state="getValidationState(validationContext)" v-model="datosActualizarSocioNegocio.idTipoSocioFuncionario" value-field="idTipoSocioNegocio" text-field="descripcion" :options="listaTipoSociosNegocioAntisoborno">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosActualizarSocioNegocio.isSocioNegocio==3">
                        <validation-provider name="n° de ruc" :rules="{required: true,numeric:true,max:11}" v-slot="validationContext">
                            <b-form-group label="N° de ruc:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el ruc" v-model="datosActualizarSocioNegocio.numeroRuc"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosActualizarSocioNegocio.isSocioNegocio==3">
                        <validation-provider name="razón social" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Razón Social:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la razón social" v-model="datosActualizarSocioNegocio.razonSocial"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosActualizarSocioNegocio.isSocioNegocio==3">
                        <validation-provider name="contacto" v-slot="validationContext">
                            <b-form-group label="Contacto:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el contacto" v-model="datosActualizarSocioNegocio.contacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosActualizarSocioNegocio.isSocioNegocio==3">
                        <validation-provider name="teléfono" :rules="{numeric:true}" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosActualizarSocioNegocio.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosActualizarSocioNegocio.isSocioNegocio==3">
                        <validation-provider name="email" :rules="{email:true}" v-slot="validationContext">
                            <b-form-group label="Email:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el email" v-model="datosActualizarSocioNegocio.email"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo de relación" v-slot="validationContext">
                            <b-form-group label="Tipo de relación:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el tipo de relación" v-model="datosActualizarSocioNegocio.tipoRelacion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="Estado del socio de negocio">
                            <b-form-group label="Estado del socio de negocio:" class="mb-2">
                                <b-form-radio-group plain v-model="datosActualizarSocioNegocio.isActivo" :options="[{value:2,text:'Activo'},{value:1,text:'Suspendido'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="detalles" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Detalles:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese los detalles" v-model="datosActualizarSocioNegocio.detalles"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarSocioNegocio = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalRegistrarFuncionarioPublico" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo funcionario público</span>
            </h6>
            <CButtonClose @click="modalRegistrarFuncionarioPublico = false" class="text-white" />
        </template>
        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarFuncionarioPublico)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="funcionario público" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Funcionario público:" class="mb-2">
                                <b-form-select :state="getValidationState(validationContext)" v-model="datosFuncionarioPublico.idTipoSocioFuncionario" value-field="idTipoFuncionario" text-field="descripcion" :options="listaTipoFuncionariosPublicos">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="n° de ruc de entidad" :rules="{numeric:true,max:11}" v-slot="validationContext">
                            <b-form-group label="N° de ruc de entidad:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el ruc" v-model="datosFuncionarioPublico.numeroRuc"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="razón social de entidad" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Razón Social de entidad:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la razón social" v-model="datosFuncionarioPublico.razonSocial"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="contacto" v-slot="validationContext">
                            <b-form-group label="Contacto:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el contacto" v-model="datosFuncionarioPublico.contacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="teléfono" :rules="{numeric:true}" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosFuncionarioPublico.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="email" :rules="{email:true}" v-slot="validationContext">
                            <b-form-group label="Email:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el email" v-model="datosFuncionarioPublico.email"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo de relación" v-slot="validationContext">
                            <b-form-group label="Tipo de relación:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el tipo de relación" v-model="datosFuncionarioPublico.tipoRelacion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="Estado del socio de negocio">
                            <b-form-group label="Estado del socio de negocio:" class="mb-2">
                                <b-form-radio-group plain v-model="datosFuncionarioPublico.isActivo" :options="[{value:2,text:'Activo'},{value:1,text:'Suspendido'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="detalles" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Detalles:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese los detalles" v-model="datosFuncionarioPublico.detalles"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarFuncionarioPublico = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarFuncionarioPublico" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Actualizar funcionario público</span>
            </h6>
            <CButtonClose @click="modalActualizarFuncionarioPublico = false" class="text-white" />
        </template>
        <validation-observer ref="observer4" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarFuncionarioPublico)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="funcionario público" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Funcionario público:" class="mb-2">
                                <b-form-select :state="getValidationState(validationContext)" v-model="datosActualizarFuncionarioPublico.idTipoSocioFuncionario" value-field="idTipoFuncionario" text-field="descripcion" :options="listaTipoFuncionariosPublicos">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="n° de ruc" :rules="{required: true,numeric:true,max:11}" v-slot="validationContext">
                            <b-form-group label="N° de ruc:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el ruc" v-model="datosActualizarFuncionarioPublico.numeroRuc"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="razón social" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Razón Social:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la razón social" v-model="datosActualizarFuncionarioPublico.razonSocial"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="contacto" v-slot="validationContext">
                            <b-form-group label="Contacto:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el contacto" v-model="datosActualizarFuncionarioPublico.contacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="teléfono" :rules="{numeric:true}" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosActualizarFuncionarioPublico.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="email" :rules="{email:true}" v-slot="validationContext">
                            <b-form-group label="Email:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el email" v-model="datosActualizarFuncionarioPublico.email"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo de relación" v-slot="validationContext">
                            <b-form-group label="Tipo de relación:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el tipo de relación" v-model="datosActualizarFuncionarioPublico.tipoRelacion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="Estado del socio de negocio">
                            <b-form-group label="Estado del socio de negocio:" class="mb-2">
                                <b-form-radio-group plain v-model="datosActualizarFuncionarioPublico.isActivo" :options="[{value:2,text:'Activo'},{value:1,text:'Suspendido'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="detalles" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Detalles:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese los detalles" v-model="datosActualizarFuncionarioPublico.detalles"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarFuncionarioPublico = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            disabled: false,
            listaSociosNegocio: [],
            listaFuncionarios: [],
            camposPoliticas: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "idTipoSocioFuncionario",
                    label: "Socio Negocio",
                    class: "text-center",
                },
                {
                    key: "razonSocial",
                    label: "Razón Social",
                    class: "text-center",
                },
                {
                    key: "ruc",
                    label: "Ruc",
                    class: "text-center",
                },
                {
                    key: "telefono",
                    label: "Teléfono",
                    class: "text-center",
                },
                {
                    key: "email",
                    label: "Correo",
                    class: "text-center",
                },
                {
                    key: "contacto",
                    label: "Contacto",
                    class: "text-center",
                },
                {
                    key: "isActivo",
                    label: "Estado",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            datosSocioNegocio: {
                idCliente: '',
                isSocioNegocio: 2,
                idClienteProveedor: '',
                idTipoSocioFuncionario: null,
                razonSocial: '',
                numeroRuc: '',
                contacto: '',
                telefono: '',
                email: '',
                tipoRelacion: '',
                detalles: '',
                isActivo: 2,
                ejercicio: JSON.parse(localStorage.settings).yExcs,
                socioFuncionario: 1,
            },
            datosActualizarSocioNegocio: {
                idSocioNegocio: '',
                idCliente: '',
                isSocioNegocio: '',
                idClienteProveedor: '',
                idTipoSocioFuncionario: null,
                razonSocial: '',
                numeroRuc: '',
                contacto: '',
                telefono: '',
                email: '',
                tipoRelacion: '',
                detalles: '',
                isActivo: '',
                ejercicio: '',
            },
            datosFuncionarioPublico: {
                idCliente: '',
                isSocioNegocio: 0,
                idClienteProveedor: '',
                idTipoSocioFuncionario: null,
                razonSocial: '',
                numeroRuc: '',
                contacto: '',
                telefono: '',
                email: '',
                tipoRelacion: '',
                detalles: '',
                isActivo: 2,
                ejercicio: JSON.parse(localStorage.settings).yExcs,
                socioFuncionario: 2,
            },
            datosActualizarFuncionarioPublico: {
                idSocioNegocio: '',
                idCliente: '',
                isSocioNegocio: 0,
                idClienteProveedor: '',
                idTipoSocioFuncionario: null,
                razonSocial: '',
                numeroRuc: '',
                contacto: '',
                telefono: '',
                email: '',
                tipoRelacion: '',
                detalles: '',
                isActivo: '',
                ejercicio: '',
            },
            modalRegistrarSocioNegocio: false,
            modalActualizarSocioNegocio: false,
            modalRegistrarFuncionarioPublico: false,
            modalActualizarFuncionarioPublico: false,
            listaClientes: [],
            listaProveedores: [],
            listaTipoSociosNegocioAntisoborno: [{
                    idTipoSocioNegocio: 1,
                    descripcion: 'Operaciones conjunta'
                },
                {
                    idTipoSocioNegocio: 2,
                    descripcion: 'Socios de operaciones conjuntas'
                },
                {
                    idTipoSocioNegocio: 3,
                    descripcion: 'Miembro de consorcio'
                },
                {
                    idTipoSocioNegocio: 4,
                    descripcion: 'Proveedor externo'
                },
                {
                    idTipoSocioNegocio: 5,
                    descripcion: 'Contratistas'
                },
                {
                    idTipoSocioNegocio: 6,
                    descripcion: 'Consultores'
                },
                {
                    idTipoSocioNegocio: 7,
                    descripcion: 'Subcontratistas'
                },
                {
                    idTipoSocioNegocio: 8,
                    descripcion: 'Vendedores'
                },
                {
                    idTipoSocioNegocio: 9,
                    descripcion: 'Asesores'
                },
                {
                    idTipoSocioNegocio: 10,
                    descripcion: 'Agentes'
                },
                {
                    idTipoSocioNegocio: 11,
                    descripcion: 'Distribuidores'
                },
                {
                    idTipoSocioNegocio: 12,
                    descripcion: 'Representantes'
                },
                {
                    idTipoSocioNegocio: 13,
                    descripcion: 'Intermediarios'
                },
                {
                    idTipoSocioNegocio: 14,
                    descripcion: 'Inversores'
                },
                {
                    idTipoSocioNegocio: 15,
                    descripcion: 'Otros'
                },
            ],
            listaTipoFuncionariosPublicos: [{
                    idTipoFuncionario: 1,
                    descripcion: 'Legislativo'
                },
                {
                    idTipoFuncionario: 2,
                    descripcion: 'Administrativo'
                },
                {
                    idTipoFuncionario: 3,
                    descripcion: 'Judicional'
                },
                {
                    idTipoFuncionario: 4,
                    descripcion: 'Designación'
                },
                {
                    idTipoFuncionario: 5,
                    descripcion: 'Sucesor'
                },
                {
                    idTipoFuncionario: 6,
                    descripcion: 'Otro - función pública'
                },
            ],
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        blurCliente1() {
            this.computedForm.refs.cliente.validate();
        },
        blurProveedor1() {
            this.computedForm.refs.proveedor.validate();
        },
        blurCliente2() {
            this.computedForm3.refs.cliente.validate();
        },
        blurProveedor2() {
            this.computedForm3.refs.proveedor.validate();
        },
        blurCliente1Actualizar() {
            this.computedForm2.refs.cliente.validate();
        },
        blurProveedor1Actualizar() {
            this.computedForm2.refs.proveedor.validate();
        },
        blurCliente2Actualizar() {
            this.computedForm4.refs.cliente.validate();
        },
        blurProveedor2Actualizar() {
            this.computedForm4.refs.proveedor.validate();
        },
        resetInput(cond) {
            if (cond == 1) {
                this.datosSocioNegocio.idClienteProveedor = '';
                this.datosSocioNegocio.razonSocial = '';
                this.datosSocioNegocio.numeroRuc = '';
                this.datosSocioNegocio.contacto = '';
                this.datosSocioNegocio.telefono = '';
                this.datosSocioNegocio.email = '';
                if (this.datosSocioNegocio.isSocioNegocio == 2) {
                    this.datosSocioNegocio.idTipoSocioFuncionario = -2;
                } else if (this.datosSocioNegocio.isSocioNegocio == 1) {
                    this.datosSocioNegocio.idTipoSocioFuncionario = -1;
                }
                else {
                    this.datosSocioNegocio.idTipoSocioFuncionario = null;
                }
            }
            if (cond == 2) {
                this.datosActualizarSocioNegocio.idClienteProveedor = '';
                this.datosActualizarSocioNegocio.razonSocial = '';
                this.datosActualizarSocioNegocio.numeroRuc = '';
                this.datosActualizarSocioNegocio.contacto = '';
                this.datosActualizarSocioNegocio.telefono = '';
                this.datosActualizarSocioNegocio.email = '';
                if (this.datosActualizarSocioNegocio.isSocioNegocio == 2) {
                    this.datosActualizarSocioNegocio.idTipoSocioFuncionario = -2;
                } else if (this.datosActualizarSocioNegocio.isSocioNegocio == 1) {
                    this.datosActualizarSocioNegocio.idTipoSocioFuncionario = -1;
                }
                else {
                    this.datosActualizarSocioNegocio.idTipoSocioFuncionario = null;
                }
            }
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }

                //this.listaOrganigramas.documento = this.$refs.file.files[0];
            }
        },
        handleFileUploadActualizar(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileActualizar"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileActualizar"].reset();
                    return;
                }

                //this.listaOrganigramas.documento = this.$refs.file.files[0];
            }
        },
        descargarDocumento(url) {
            window.open(url)
        },
        listarClientes() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-clientes-proyectos", {
                        params: {
                            idCliente: me.datosSocioNegocio.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaClientes = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarProveedores() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-proveedores", {
                        params: {
                            idCliente: me.datosSocioNegocio.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaProveedores = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarSociosNegocio() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-socios-negocio-sga", {
                        params: {
                            idCliente: me.datosSocioNegocio.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs,
                        },
                    }
                )
                .then(function (response) {
                    me.listaSociosNegocio = response.data.filter(x => x.socioFuncionario == 1);
                    me.listaFuncionarios = response.data.filter(x => x.socioFuncionario == 2);
                    me.filasTotales = me.listaSociosNegocio.length + me.listaFuncionarios.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarSocioNegocio() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-socios-negocio-sga",
                    me.datosSocioNegocio, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalRegistrarSocioNegocio = false;
                    me.listarSociosNegocio();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        actualizarSocioNegocio() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-socios-negocio-sga",
                    me.datosActualizarSocioNegocio, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalActualizarSocioNegocio = false;
                    me.listarSociosNegocio();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        registrarFuncionarioPublico() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-socios-negocio-sga",
                    me.datosFuncionarioPublico, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalRegistrarFuncionarioPublico = false;
                    me.listarSociosNegocio();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        actualizarFuncionarioPublico() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-socios-negocio-sga",
                    me.datosActualizarFuncionarioPublico, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalActualizarFuncionarioPublico = false;
                    me.listarSociosNegocio();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },

        eliminarSocioNegocio(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el socio de negocio?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-socio-negocio-sga", {
                                idSocioNegocio: param.item.idSocioNegocio
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarSociosNegocio();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        abrirModalActualizarSocioNegocio(param) {
            this.datosActualizarSocioNegocio.idSocioNegocio = param.item.idSocioNegocio;
            this.datosActualizarSocioNegocio.idCliente = param.item.idCliente;
            this.datosActualizarSocioNegocio.isSocioNegocio = param.item.isSocioNegocio;
            this.datosActualizarSocioNegocio.idClienteProveedor = param.item.idClienteProveedor;
            this.datosActualizarSocioNegocio.idTipoSocioFuncionario = param.item.idTipoSocioFuncionario;
            this.datosActualizarSocioNegocio.razonSocial = param.item.razonSocial;
            this.datosActualizarSocioNegocio.numeroRuc = param.item.ruc;
            this.datosActualizarSocioNegocio.contacto = param.item.contacto;
            this.datosActualizarSocioNegocio.telefono = param.item.telefono;
            this.datosActualizarSocioNegocio.email = param.item.email;
            this.datosActualizarSocioNegocio.tipoRelacion = param.item.tipoRelacion;
            this.datosActualizarSocioNegocio.tipoRelacion = param.item.tipoRelacion;
            this.datosActualizarSocioNegocio.detalles = param.item.detalles;
            this.datosActualizarSocioNegocio.isActivo = param.item.isActivo;
            this.datosActualizarSocioNegocio.socioFuncionario = param.item.socioFuncionario;
            this.datosActualizarSocioNegocio.ejercicio = param.item.ejercicio;
            this.modalActualizarSocioNegocio = true
        },
        abrirModalActualizarFuncionarioPublico(param) {
            this.datosActualizarFuncionarioPublico.idSocioNegocio = param.item.idSocioNegocio;
            this.datosActualizarFuncionarioPublico.idCliente = param.item.idCliente;
            this.datosActualizarFuncionarioPublico.isSocioNegocio = param.item.isSocioNegocio;
            this.datosActualizarFuncionarioPublico.idClienteProveedor = param.item.idClienteProveedor;
            this.datosActualizarFuncionarioPublico.idTipoSocioFuncionario = param.item.idTipoSocioFuncionario;
            this.datosActualizarFuncionarioPublico.razonSocial = param.item.razonSocial;
            this.datosActualizarFuncionarioPublico.numeroRuc = param.item.ruc;
            this.datosActualizarFuncionarioPublico.contacto = param.item.contacto;
            this.datosActualizarFuncionarioPublico.telefono = param.item.telefono;
            this.datosActualizarFuncionarioPublico.email = param.item.email;
            this.datosActualizarFuncionarioPublico.tipoRelacion = param.item.tipoRelacion;
            this.datosActualizarFuncionarioPublico.detalles = param.item.detalles;
            this.datosActualizarFuncionarioPublico.tipoRelacion = param.item.tipoRelacion;
            this.datosActualizarFuncionarioPublico.isActivo = param.item.isActivo;
            this.datosActualizarFuncionarioPublico.socioFuncionario = param.item.socioFuncionario;
            this.datosActualizarFuncionarioPublico.ejercicio = param.item.ejercicio;
            this.modalActualizarFuncionarioPublico = true
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalRegistrarSocioNegocio() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosSocioNegocio.isSocioNegocio = 2;
            this.datosSocioNegocio.idClienteProveedor = '';
            this.datosSocioNegocio.idTipoSocioFuncionario = null;
            this.datosSocioNegocio.razonSocial = '';
            this.datosSocioNegocio.numeroRuc = '';
            this.datosSocioNegocio.contacto = '';
            this.datosSocioNegocio.telefono = '';
            this.datosSocioNegocio.email = '';
            this.datosSocioNegocio.tipoRelacion = '';
            this.datosSocioNegocio.detalles = '';
            this.datosSocioNegocio.isActivo = 2;
        },
        resetModalRegistrarFuncionarioPublico() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });
            this.datosFuncionarioPublico.isSocioNegocio = 0;
            this.datosFuncionarioPublico.idClienteProveedor = '';
            this.datosFuncionarioPublico.idTipoSocioFuncionario = null;
            this.datosFuncionarioPublico.razonSocial = '';
            this.datosFuncionarioPublico.numeroRuc = '';
            this.datosFuncionarioPublico.contacto = '';
            this.datosFuncionarioPublico.telefono = '';
            this.datosFuncionarioPublico.email = '';
            this.datosFuncionarioPublico.tipoRelacion = '';
            this.datosFuncionarioPublico.detalles = '';
            this.datosFuncionarioPublico.isActivo = 2;
        },
        resetModalActualizarSocioNegocio() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
        resetModalActualizarFuncionarioPublico() {
            this.$nextTick(() => {
                this.$refs.observer4.reset();
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedForm2() {
            return this.$refs.observer2;
        },
        computedForm3() {
            return this.$refs.observer3;
        },
        computedForm4() {
            return this.$refs.observer4;
        },
    },
    watch: {
        modalRegistrarSocioNegocio: function (val) {
            if (val == false) {
                this.resetModalRegistrarSocioNegocio();
            }
        },
        modalActualizarSocioNegocio: function (val) {
            if (val == false) {
                this.resetModalActualizarSocioNegocio();
            }
        },
        modalRegistrarFuncionarioPublico: function (val) {
            if (val == false) {
                this.resetModalRegistrarFuncionarioPublico();
            }
        },
        modalActualizarFuncionarioPublico: function (val) {
            if (val == false) {
                this.resetModalActualizarFuncionarioPublico();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSocioNegocio.idCliente = this.datosActualizarSocioNegocio.idCliente = this.datosFuncionarioPublico.idCliente = this.datosActualizarFuncionarioPublico.idCliente = user.uidClient;
            this.listarSociosNegocio();
            this.listarClientes();
            this.listarProveedores();
        }
    }

}
</script>
